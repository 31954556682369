<template>
  <ContentWrapper :links="topLinks">
    <p>
      此功能用于重建全站的搜索引擎索引. 10000条约需要3分钟的时间, 期间搜索和列表页会不正常, 请谨慎使用
    </p>
    <p>
      <Button @click="reIndex" size="large">重建商品索引</Button>
    </p>
    <br />
    <p>
      <Button @click="reIndexShop" size="large">重建店铺索引</Button>
    </p>
    <br />
    <p>
      <Button @click="reIndexGroupbuy" size="large">重建集单索引</Button>
    </p>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { reIndexItem, reIndexShop, reIndexGroupbuy } from "@/api"
import { apiUtil } from "@/util"

export default {
  name: "AppIndexPage",
  components: { ContentWrapper },
  data() {
    return {
      topLinks: [{ title: "应用索引", link: this.$route.path }],
      loading: true,
    }
  },
  computed: {},
  watch: {},
  methods: {
    async reIndex() {
      apiUtil.call(this, async () => {
        await reIndexItem()
      })
      this.$Message.success("异步请求已发出, 可以安全离开这个页面")
    },
    async reIndexShop() {
      apiUtil.call(this, async () => {
        await reIndexShop()
      })
      this.$Message.success("异步请求已发出, 可以安全离开这个页面")
    },
    async reIndexGroupbuy() {
      apiUtil.call(this, async () => {
        await reIndexGroupbuy()
      })
      this.$Message.success("异步请求已发出, 可以安全离开这个页面")
    },
  },
}
</script>
